<template>
  <div class="row mb-3">
    <ed-modal v-model="boolExibirCadastroEmpresa" width="80%">
      <div slot="title">
        {{
          formData.intId
            ? "Editar registro vinculado"
            : "Vincular nova registro"
        }} :: 
        <span v-if="empresa">
          {{empresa.strNome}}  
        </span> 
        <span v-if="funcionario">
          {{funcionario.strNome}}  
        </span> 
      </div>

      <div class="" slot="content">
        <ed-form
          :formData="formData"
          :handlerSave="salvar"
          disableFixedSave
        >
          <div class="row">
            <ed-input-auto-complete
              v-if="!boolEmpresa"
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formData.intCadastroGeralEmpresaId"
              name="intCadastroGeralEmpresaId"
              item-text="strNome"
              label="Empresa"
              route="Cadastro/CadastroGeral"
              rules="required"
              @selected="setRegistroVincilado"
              :filters="{ intTipoCadastroGeralId: 58 }"
              :itemList="formData.empresa ? [formData.empresa] : []"
            />

            <ed-input-auto-complete
              v-if="boolEmpresa"
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formData.intFuncionarioId"
              name="intFuncionarioId"
              item-text="strNome"
              label="Funcionário"
              route="Cadastro/Funcionario"
              rules="required"
              @selected="setRegistroVincilado"
              :itemList="formData.funcionario ? [formData.funcionario] : []"
            />

            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
              v-model="formData.intCadastroGeralUnidadeId"
              label="Unidade"
              name="intCadastroGeralUnidadeId"
              route="Cadastro/CadastroGeralUnidade"
              rules="required"
              :filters="{
                intCadastroGeralEmpresaId:
                  this.formData.intCadastroGeralEmpresaId,
              }"
              @selected="setCadastroUnidade"
              :itemList="formData.unidade ? [formData.unidade] : []"
            />

            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
              v-model="formData.intCadastroGeralSetorId"
              label="Setor"
              name="intCadastroGeralSetorId"
              route="Cadastro/CadastroGeralSetor"
              rules="required"
              @selected="setCadastroSetor"
              :filters="{
                intCadastroGeralEmpresaId:
                  this.formData.intCadastroGeralEmpresaId,
              }"
              :itemList="formData.setor ? [formData.setor] : []"
            />

            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
              v-model="formData.intFuncaoId"
              label="Função"
              name="intFuncaoId"
              route="Cadastro/Funcao"
              rules="required"
              :filters="{
                intCadastroGeralEmpresaId:
                  this.formData.intCadastroGeralEmpresaId,
              }"
              :itemList="formData.funcao ? [formData.funcao] : []"
              @selected="setCadastroFuncao"
            />

            <ed-input-auto-complete
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
              v-model="formData.strCodigoEsocialCategoria"
              label="Categoria eSocial"
              name="strCodigoEsocialCategoria"
              item-text="strNome"
              item-value="strCodigo"
              route="Esocial/EsocialTabelaConteudo"
              :filters="{
                strBusca: this.formData.strCodigoEsocialCategoria,
                intEsocialTabelaId: 1,
              }"
              :initializeItems="formData.strCodigoEsocialCategoria && formData.intId>0"
            />

            <ed-input-text
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
              v-model="formData.strMatricula"
              name="strMatricula"
              label="Matrícula"
            />

            <ed-input-date
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
              v-model="formData.strDataAdmissao"
              name="strDataAdmissao"
              label="Data de Admissão"
            />

            <!-- <ed-input-switch
              class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"
              v-model="formData.boolAtivo"
              name="boolAtivo"
              label="Empresa ativo?"
              required
              rules="required"
            /> -->
            <ed-input-switch
              v-if="!boolEmpresa"
              class="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10"
              v-model="formData.boolPrincipal"
              name="boolPrincipal"
              label="Cadastro principal"
            />
          </div>
        </ed-form>
      </div>
    </ed-modal>

    <ed-table
    :key="$root.$session.versao"
      strModel="Cadastro/CadastroGeralFuncionario"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :loading="loading"
      :pagination="objPagination"
      :filter="formFiltros"
      @register="onRegister"
      @edit="onEdit"
      @delete="onDelete"
      @search="getFuncionario"
      :config="{ registerName: 'Inserir Novo Vínculo Trabalhista' }"
    />
  </div>
</template>

<script>
import {
  EdForm,
  EdTable,
  EdModal,
  EdButton,
  EdInputAutoComplete,
  EdInputSwitch,
  EdInputText,
  EdInputDate,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    funcionario: {
      type: [Object],
      required: false,
      default: null,
    },
    empresa: {
      type: [Object],
      required: false,
      default: null,
    },
    boolEmpresa: {
      type: [Boolean],
      required: false,
      default: false,
    },
  },
  components: {
    EdForm,
    EdTable,
    EdModal,
    EdButton,
    EdInputAutoComplete,
    EdInputSwitch,
    EdInputText,
    EdInputDate,
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      loading: false,
      boolExibirCadastroEmpresa: false,
      arrayHeaders: [
        {
          text: "Empresa",
          sortable: true,
          showUnidade: true,
          showFuncao: true,
          showSetor: true,
          value: "strNome",
        },
        {
          text: "Dt. Admissão",
          sortable: true,
          value: "strDataAdmissao",
        },
        {
          text: "Cat. eSocial",
          sortable: true,
          value: "strCodigoEsocialCategoria",
        },
        {
          text: "Matrícula",
          sortable: true,
          value: "strMatricula",
        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros:{
        intCadastroGeralEmpresaId: null,
        intFuncionarioId:null
      },
      formData: {
        intId: 0,
        intCadastroGeralEmpresaId: this.empresa ? this.empresa.intId : null,
        intFuncionarioId: this.funcionario ? this.funcionario.intId : null,
        intCadastroGeralSetorId: null,
        intCadastroGeralUnidadeId: null,
        intFuncaoId: null,
        empresa: null,
        boolAtivo: 1,
        boolPrincipal: 1,
      },
    };
  },
  watch: {
  },
  methods: {
    initialize() {

      if (this.boolEmpresa == "funcionarios") {
        this.arrayHeaders[0].text = "Funcionário";
        this.arrayHeaders[4].hidden = true;
      }
      this.formFiltros['intFuncionarioId'] = this.funcionario ? this.funcionario.intId : null
      this.formFiltros['intCadastroGeralEmpresaId'] = this.empresa ? this.empresa.intId : null

      this.getFuncionario();
    },

    onRegister() {
      this.boolExibirCadastroEmpresa = !this.boolExibirCadastroEmpresa;


      this.formData = {
        intId: 0,
        intFuncionarioId:this.funcionario ? this.funcionario.intId : null,
        intCadastroGeralEmpresaId:this.empresa ? this.empresa.intId : null,
        intCadastroGeralSetorId: null,
        intCadastroGeralUnidadeId: null,
        strCodigoEsocialCategoria: null,
        strMatricula: null,
        intFuncaoId: null,
        empresa: this.empresa ? this.empresa : null,
        funcionario: this.funcionario ? this.funcionario : null,
        unidade: null,
        setor: null,
        funcao: null,
        strDataAdmissao: null,
        boolAtivo: 1,
        boolPrincipal: this.arrayRows.length ? 0 : 1,
      };
    },

    onEdit(data) {
      this.formData =  _.cloneDeep(data);
      this.boolExibirCadastroEmpresa = !this.boolExibirCadastroEmpresa;
    },

    onDelete(data) {
      
    },

    setRegistroVincilado(obj) {
      if (!this.boolEmpresa) {
        this.formData.funcionario = obj;
      } else {
        this.formData.empresa = obj;
      }
    },

    setCadastroUnidade(objEmpresa) {
      if(objEmpresa){
        this.formData.unidade = objEmpresa;
      }
    },

    setCadastroSetor(objEmpresa) {
      if(objEmpresa){
        this.formData.setor = objEmpresa;
      }
    },

    setCadastroFuncao(objEmpresa) {
      if(objEmpresa){
        this.formData.funcao = objEmpresa;
      }
    },

    getFuncionario() {

      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get(
          "Cadastro/CadastroGeralFuncionario",
          this.formFiltros
        )
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objModel) => {
              
              let strNome = objModel.empresa.strNome;

              if (this.boolEmpresa) {
                strNome = objModel.funcionario.strNome;
              }

              if(objModel.boolPrincipal){
                strNome = '<span title="Cadastro Principal" style="color:'+this.$vuetify.theme.themes.light.success+';font-weight:bold">'+strNome+'</span>'
              }

              let item = {
                intId: objModel.intId,
                intCadastroGeralEmpresaId: objModel.intCadastroGeralEmpresaId,
                intFuncionarioId: objModel.intFuncionarioId,
                strCnpj: objModel.empresa.strCnpj,
                strNome: strNome,
                strUnidade: objModel.unidade ? objModel.unidade.strNome : "---",
                strSetor: objModel.setor ? objModel.setor.strNome : "---",
                strFuncao: objModel.funcao ? objModel.funcao.strNome : "---",
                strDataAdmissao: objModel.strDataAdmissao
                  ? this.$utilidade.toDate(objModel.strDataAdmissao, true)
                  : "---",
                strCodigoEsocialCategoria: objModel.strCodigoEsocialCategoria
                  ? objModel.strCodigoEsocialCategoria
                  : "---",
                strMatricula: objModel.strMatricula ? objModel.strMatricula : "---",
                boolAtivo: objModel.boolAtivo,
                boolPrincipal: objModel.boolPrincipal ? "Sim" : "Não",
                _item: objModel,
              };


              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }

        });
    },

    salvar() {
      this.$root.$request
        .post("Cadastro/CadastroGeralFuncionario", this.formData)
        .then((objResponse) => {
          if (objResponse.status == 200) {
            this.getFuncionario();
            this.boolExibirCadastroEmpresa = !this.boolExibirCadastroEmpresa;
          }
        });
    },
  },
};
</script>
